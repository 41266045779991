import React, { useState } from 'react';
import checkmarkIcon from '../../assets/checkmark.svg';
import aiIcon from '../../assets/ai_icon.svg';
import './plans.css';

const Plans = () => {
  const [isAnnual, setIsAnnual] = useState(true);

  const togglePlan = () => {
    setIsAnnual(!isAnnual);
  };

  return (
    <div className="comparison-table plans-padding" id="plans" tabindex="4">
      <div className="comparison-title">
        <h2>Prisplaner</h2>
        <p>Du får en allt-i-ett lösning för verksamhetsstyrning - med översikt av hela din verksamhet, HACCP plan, övervakning, egenkontroller, rapporter och mycket mer - helt utan bindningstid!</p>
      </div>
      
      <div className="toggle-container">
        <button className={`toggle-button ${isAnnual ? 'active' : ''}`} onClick={togglePlan} tabindex="4">
          Årsvis
          <div className={`discount ${!isAnnual ? '' : 'active'}`}>
            <span>Spara 10%</span>
          </div>
        </button>
        <button className={`toggle-button ${!isAnnual ? 'active' : ''}`} onClick={togglePlan} tabindex="4">
          Månadsvis
        </button>
      </div>

      <div className="plans">
      {/* <div className="plan" tabindex="4">
          <div>
            <h3>Bas</h3>
            <p className="sub-label plan-description">För verksamheter som vill säkerställa att de följer lagar & regler.</p>
            <div className="flex">
              {isAnnual ? (
                <>
                  <h4 className="inline-block line-through inactive">545 kr</h4>
                  <h4 className="inline-block">495 kr</h4>
                  <p className="sub-label inline-block">/mån</p>
                </>
              ) : (
                <>
                  <h4 className="inline-block">545 kr</h4>
                  <p className="sub-label inline-block">/mån</p>
                </>
              )}
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Övrigt</p>
              <ul className="plan-features">
              <li><img src={aiIcon} alt="Checkmark Icon" className="checkmark-icon" /><span className='bold'>Standard </span>AI</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" /><span className='bold'>5 st</span> användare</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Filhantering med <span className='bold'>1 TB</span> lagringsutrymme</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" /><span className='bold'>Standard</span> support</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Tillgängligt innehåll<span className='bold'> Safe & legal</span></li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Huvudfunktioner</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Översikt av din verksamhets processer & flöden</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Riskhantering & HACCP plan</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Övervakning med skräddarsydda kontroller</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Avvikelsehantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Rutin- & dokumentstyrning</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Leverantörs- & certifikatshantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Spårbarhet & historik</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Skapa rapporter - faroanalys, farostyrningsplan m.fl.</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Egenkontroll</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Säkerhet & backup</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Alltid uppdaterat</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Använd på alla plattformar</li>
              </ul>
            </div>
            <div className="divider"></div>
            <div className="plan-section">
              <p className="sub-label tiny">Tillägg</p>
              <ul className="plan-features">
              <ul className="addons-features">
                <li>1 till Affärsenhet<span className="price-small">• 295 kr/mån</span></li>
                <li>1 TB extra lagringsutrymme<span className="price-small">• 99 kr/mån</span></li>
                <li>HR-paket<span className="price-small">• 249 kr/mån</span></li>
                <li>Arbetsmiljö-paket<span className="price-small">• 249 kr/mån</span></li>
                <li>Skadedjurs-paket<span className="price-small">*</span></li>
                <li>KRAV<span className="price-small">• 99 kr/mån</span></li>
                <li>EKO<span className="price-small">• 99 kr/mån</span></li>
                <li>MSC / ASC<span className="price-small">• 99 kr/mån</span></li>
              </ul>
              </ul>
            </div>
            <div className="plan-section">
               <p className="sub-label tiny">Konsultation</p>
               <ul className="addons-features">
                 <li>Få stöd av våra livsmedelsexperter med över 100 års kombinerad erfarenhet<span className="price-small">*</span></li>
                 <br></br>
                 <p className="sub-label explainer">*Kontakta oss för offert</p>
               </ul>
             </div>
          </div>
          <br></br>
          <div className="plan-button">
            <a tabindex="4" href="/contact" className="button-secondary">Kontakta oss</a>
          </div>
        </div> */}
        <div className="plan recommended" tabindex="4">
          <div>
            <h3 className="highlighted">Premium</h3>
            <p className="sub-label plan-description">Passar de flesta organisationerna med eller utan certifiering.</p>
            <div className="baseline flex">
              {isAnnual ? (
                <>
                  <h4 className="inline-block line-through inactive">1106 kr</h4>
                  <h4 className="inline-block">995 kr</h4>
                  <p className="sub-label inline-block no-margin-top">/mån ex. moms</p>
                </>
              ) : (
                <>
                  <h4 className="inline-block">1106 kr</h4>
                  <p className="sub-label inline-block no-margin-top">/mån ex. moms</p>
                </>
              )}
            </div>
            <div className="plan-section">
              {/* <p className="sub-label tiny">Övrigt</p> */}
              <ul className="plan-features">
                <li><img src={aiIcon} alt="Checkmark Icon" className="checkmark-icon" />AI: <span className='bold'>Avancerad </span></li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Användare: <span className='bold'>Obegränsat</span></li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Inkluderat innehåll*: <span className='bold'>IP-standard, Safe & legal</span></li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Affärsenheter: <span className='bold'>1 st</span></li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Lagringsutrymme: <span className='bold'>1 TB</span></li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Supportnivå: <span className='bold'>Premium</span></li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Huvudfunktioner</p>
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Översikt av din verksamhets processer & flöden</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Egenkontroll</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Riskhantering & HACCP plan</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Övervakning med skräddarsydda kontroller</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Avvikelsehantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Rutin- & dokumentstyrning</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Leverantörs- & certifikatshantering</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Spårbarhet & historik</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Rapport & analys - HACCP, Sårbarhet, Affärsrisk</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Säkerhet & backup</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Alltid uppdaterat</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Använd på alla plattformar</li>
                <br></br>
                <p className="sub-label explainer">*Alla nödvändiga Rutiner, Dokument, Risker och Kontroller för att leva upp till er certifiering.</p>
              </ul>
            </div>
          </div>
          <br></br>
          <div className="plan-button">
            {isAnnual ? (
                <a tabindex="4" href="https://buy.stripe.com/eVadRc0iIaV562s002?locale=sv" className="button-primary">Prenumerera nu</a>
              ) : (
                <a tabindex="4" href="https://buy.stripe.com/eVa00me9yaV59eE147?locale=sv" className="button-primary">Prenumerera nu</a>
              )}
          </div>
        </div>
        
        <div className="plan"  tabindex="4">
          <div>
            <h3>Enterprise</h3>
            <p className="sub-label plan-description">För större, mer komplexa organisationer med behov av skräddarsydda lösningar.</p>
            <div>
              <h4 className="inline-block">Skräddarsytt pris</h4>
            </div>
            <div className="plan-section">
              <ul className="plan-features">
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Alla huvudfunktioner</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Skräddarsydd uppsättning</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Utökad säkerhet</li>
                <li><img src={checkmarkIcon} alt="Checkmark Icon" className="checkmark-icon" />Supportnivå: <span className='bold'>Exklusiv</span></li>
              </ul>
            </div>
          </div>
          <div className="plan-button">
            <a tabindex="4" href="/contact" className="button-secondary">Kontakta oss</a>
          </div>
        </div>

        <div className="plan"  tabindex="4">
          <div>
            <h3>Tillägg</h3>
            <div className="plan-section">
              <p className="sub-label tiny">Utökad verksamhet</p>
              <ul className="addons-features">
                <li>1 st extra Affärsenhet<span className="price-small">• +495 kr /mån</span></li>
                <li>1 TB extra lagringsutrymme<span className="price-small">• +95 kr/mån</span></li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Innehåll GFSI-standard</p>
              <ul className="addons-features">
                <li>FSSC 22000<span className="price-small">• +295 kr /mån</span></li>
                <li>BRCGS (BRC)<span className="price-small">• +295 kr /mån</span></li>
                <li>IFS<span className="price-small">• +295 kr /mån</span></li>
                <li>GlobalG.A.P.<span className="price-small">• +295 kr /mån</span></li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Paket*</p>
              <ul className="addons-features">
                <li>EKO<span className="price-small">• +95 kr /mån</span></li>
                <li>KRAV<span className="price-small">• +95 kr /mån</span></li>
                <li>MSC<span className="price-small">• +95 kr /mån</span></li>
                <li>ASC<span className="price-small">• +95 kr /mån</span></li>
              </ul>
            </div>
            <div className="plan-section">
              <p className="sub-label tiny">Stora paket**</p>
              <ul className="addons-features">
                <li>Skadedjur<span className="price-small">• +245 kr /mån</span></li>
                <li>HR<span className="price-small">• +245 kr /mån</span></li>
                <li>Arbetsmiljö<span className="price-small">• +245 kr /mån</span></li>
                <li>Brand<span className="price-small">• +245 kr /mån</span></li>
              </ul>
            </div>

            <br></br>
            <p className="sub-label explainer">*Paketen innehåller Rutiner, Dokument, Risker, Kontroller - alltid uppdaterat.</p>
            <p className="sub-label explainer">**Allt som paket innehåller plus möjlighet att skapa rapporter och analyser.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
