import React from 'react';
import './home.css';
import FadeInSection from '../animation/fadein_onscroll';
import Menu from '../components/menu/menu';
import Plans from '../components/plans/plans';

document.querySelectorAll('a[data-scroll]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});

function App() {
  return (
    <div className="App" id='start'>
      <Menu tabindex="0"/>

      <FadeInSection>
        <section className="">
          <Plans />
        </section>
      </FadeInSection>
      
    </div>
  );
}

// ReactDOM.render(<App />, document.getElementById('root'));

export default App;
