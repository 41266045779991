import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Terms from './pages/terms';
import TermsEN from './pages/terms_en';
import Privacy from './pages/privacy';
import Contact from './pages/contact';
import Demo from './pages/demo';
import DeleteAccount from './pages/delete_account';
import Plans from './pages/plans';

import Menu from './components/menu/menu';
import Footer from './components/footer/footer';

function App() {
  return (
    <BrowserRouter>
      <Menu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/terms_en" element={<TermsEN />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/delete_account" element={<DeleteAccount />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
